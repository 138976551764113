import React, { Component } from 'react'

class Health extends Component {
	constructor() {
		super()
		this.state = {
			name: "React"
		}
	}

	render() {
		return (<div>
			<img src="AnAppleADay.jpg" />
			<h1>
			AN APPLE A DAY</h1>
			<p>There’s an old saying, “an apple a day keeps the doctor away.”
			That has never been more true that it is today. Practicing a healthy
			diet rich in bags of apples, and platitudes of simple truths, might
			help you to live a longer and more philanthropic life.</p>
			<h2>IN LIGHT OF THE ADVANCES IN MEDICAL SCIENCE,
			WHICH ARE TRULY AMAZING, SHOULDN’T MENTAL ILLNESS BE DECREASING?</h2>
			<p>The truth to keep in mind is that these diseases are not physical,
			they don’t happen in the material body. They happen in the spirit.</p>
			<p>If you look at the current wold, the world where Prozac has been
			around for 34 years, you see a lot of people talking openly about
			mental illness. You also hear about a lot of “miracle” cures too.</p>
			<p>A recent study shows that 17.3 million adults in the US are
			diagnosed with major depressive disorder each year, (National
			Institute of Mental Health “Major Depression”, 2017). If this is
			the case then what does the say about the efficacy of pharmaceuticals
			from large corporations?</p>
			<p>The fact is that 100 years ago, only 1% of people would ever get a
			diagnosis of depression. So, there must be a root cause of all this
			hysteria. Without any further information, we are left to question
			why so many people are falling victim to this phenomena.</p>
			<p>Could it be the case that some people suffer from psychosomatic
			illnesses? That is, a non physical dejection, or loss of power?</p>
			<p>Thousands have surrendered to answers offered by L. Ron Hubbard,
			to guide them through their darkest moments and give them power to
			overcome the material world, and transcend into omnipotent, immortal
			extraterrestrial super-beings.</p>
			<p>This is why so many have reluctantly accepted the miracle of
			Dianetics. Dianetics could very well lift the spirit and restore good
			Thetans. Reach out to your local Church to learn more about how
			Dianetics can help you and the Church.</p>
		</div>)
	}
}

export default Health
