import React, { Component } from 'react';
import logo from './logo.svg';
import cross from './cross.svg';
import Family from './family.js';
import Health from './health.js';
import Spirit from './spirit.js';
import './App.css';

class App extends Component {
  constructor() {
    super();
    this.state = {
      name: "React",
      showFamily: false,
      showHealth: false,
      showSpirit: false,
      collapse: false
    }
    this.hideComponent = this.hideComponent.bind(this);
  }

  hideComponent(name = 'family') {
    this.setState({
      showFamily: false,
      showHealth: false,
      showSpirit: false,
      collapse: true
    });
    switch(name) {
      case "family":
        this.setState({showFamily: !this.state.showFamily});
        break;
      case "health":
        this.setState({showHealth: !this.state.showHealth});
        break;
      case "spirit":
        this.setState({showSpirit: !this.state.showSpirit});
	break;
      default:
        break;
    }
  }

  render(){
    const { showFamily, showHealth, showSpirit, collapse } = this.state;
    return (
      <div className="App">
        <header className={(collapse && "App-header-collapse")||"App-header"}>
          <img src={cross} className="App-cross" alt="cross" />
          <img src={logo} className="App-logo" alt="logo" />
          <h1>SCIENTOLOGY META</h1>
	  <div className="Button-box">
            <button onClick={()=>this.hideComponent('family')}>FAMILY</button>
            <button onClick={()=>this.hideComponent('health')}>HEALTH</button>
            <button onClick={()=>this.hideComponent('spirit')}>SPIRIT</button>
	  </div>
        </header>
        <body>
	  {showFamily && <Family />}
	  {showHealth && <Health />}
	  {showSpirit && <Spirit />}
        </body>
      </div>
    );
  }
}

export default App;
