import React, { Component } from "react"

class Family extends Component {
	constructor() {
		super()
		this.state = {
			name: "React"
		}
	}

	render() {
		return (<div>
			<img src="LoveAdvice.jpg" />
			<h1>
			10 WAYS TO SOOTH YOUR PARTNER</h1>
			<p>Often times, the passion of love can become so intense that one
			partner may become overwhelmed by emotions and sometimes experience
			confusion. Here are some tips on how to calm your partner and avoid
			any misunderstandings.</p>
			<h2>1 SWEET SUBTLE REMINDERS</h2>
			<p>Remind your little-love-muffin, that orgasms can often evoke
			strong emotional—and sometimes scary—experiences. Remind them
			that it’s OK and that those feelings can’t actually hurt them.</p>
			<h2>2 PULL THEM OUT OF THEIR CONFUSION</h2>
			<p>Part of bringing the love of your life back into reality is
			letting them know that they are confused and that they should listen
			to you. Once they realize that they are confused, you can help them
			heal from their psychosomatic illness.</p>
			<h2>3 ELIMINATE THE BAD THETANS</h2>
			<p>When Thetans go bad, they try to take power away from your mutual
			love. Sometimes, this means removing evil contacts from your lovers
			phone. In some rare cases, you may need to disable their phone to
			protect them from wrong doers. Never forget that messages in mobile
			devices can latch onto your lover and cloud their judgments.</p>
			<h2>4 CALL FOR HELP</h2>
			<p>Sometimes—when life partner becomes so confused and
			hysterical—it may take some help from good friends to regain
			control of the situation. Call the Church for assistance. We have all
			dealt with the pains of losing a loved ones to wicked forces.</p>
			<h2>5 EDUCATION RETREATS</h2>
			<p>You shouldn’t feel ashamed to take your lover to an education
			resort. It may seem embarrassing as an adult or a long time Church
			member, to commit your loved one for basic reinforcement of basic
			knowledge that most understand by their teen years. But, in reality,
			this is the best thing that you can do to save your relationship.
			After a retreat, you may find that your lover is better equipped with
			the emotional tools to be a good partner.</p>
			<h2>6 DON’T LEAVE THEM UNATTENDED</h2>
			<p>You would never send a toddler out into the dangerous streets
			unattended. Why would you do that to the love of your life, when they
			are experiencing psychosomatic illness?</p>
			<h2>7 CONTROL IS A RESPONSIBILITY FOR TWO</h2>
			<p>People sometimes forget that their lover’s ability to maintain
			control is very much dependent upon their own behavior. Remind them
			that it’s their love that allows you to control the evil
			extraterrestrials inside yourself. And that their bad behavior is
			hurting you.</p>
			<h2>8 SOOTHING TASTES AND SOUNDS</h2>
			<p>It always helps to use soothing music while engaging in long term
			supervision. We don’t condone the use of mind altering substances.
			But, chocolate can really ground a person in the present. Never raise
			your voice.</p>
			<h2>9 ASSERT YOURSELF</h2>
			<p>It’s hard for your lover to know when they are confused,
			because, well, they are confused. So, it’s sometimes best to just
			assert yourself as the dominate one, the same way a loving parent
			would do for their difficult child. Don’t raise your voice or
			sudden movements, as this will add to the existing fear. Just let
			them know that you’re the one making decisions and that the
			attorneys are on your side.</p>
			<h2>10 WORST CASE SCENARIO</h2>
			<p>If they lose their mind—and chose to leave their path to
			transcendence—it then becomes your responsibility to protect your
			children. You may need to take legal action and gain full custody.
			Let your lover know that you still care for them, and that you and
			the Church can recover the legal fees whenever they get back on their
			feet. Also remind them that the Church has programs to help them work
			off their legal debts to their abandoned families.</p>
			</div>
		)
	}
}

export default Family
