import React, { Component } from 'react'

class Spirit extends Component {
	constructor() {
		super()
		this.state = {
			name: "React"
		}
	}

	render() {
		return (<div>
			<img src="Dianetics.jpg" />
			<h1>WHAT IS DIANETICS?</h1>
			<p>Dianetics is a system developed by the founder of the Church of Scientology, L. Ron Hubbard, which aims to relieve psychosomatic disorder by cleansing the mind of harmful mental images.</p>
			<p>{"I've had dianetics for 20 years. I stay active and feel pretty good most of the time. See, I do a few simple things now. I'm pretty good most of the time. I'm not perfect. I try to watch my Thetans, and exercise. I get all my Dianetics testing supply from Liberty Medical."}</p>
		</div>)
	}
}

export default Spirit
